/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
/*            padding: `0px 1.0875rem 1.45rem`,*/
            padding: `0px 1.0875rem`,
              paddingTop: 0,
	      textAlign: 'center',
	      fontFamily: 'Arial, sans-serif',
          }}
        >
	    <a href="/">Etusivu</a> |&nbsp;
	    <a href="/takasivu/">Takasivu</a>
	    <hr         style={{
            margin: `0 auto`,
	    marginBottom: '0.5rem',
            maxWidth: 960,
            paddingTop: 0,
          }}
	    />
        </div>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
            <main>{children}</main>
            <footer
          style={{
	      fontSize: '0.5rem'
          }}

	    >
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
