import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { graphql } from 'gatsby'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Etusivu" keywords={[`ahonen`, `ahonen.net`, `home`]} />

    { data.allFile.edges.sort( (a,b) => ( new Date(b.node.mtime)) - (new Date(a.node.mtime))).slice(0,1).map(({ node }, index) => (
	    <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
		<Img fluid={node.childImageSharp.fluid} />
		</div>
	))}
	<hr
                style={{
		clear: 'both',
		margin: `0 auto`,
	    marginBottom: '0.5rem',
            maxWidth: 960,
            paddingTop: 0,
          }}
	    />

    </Layout>
)

export default IndexPage


export const query = graphql`
query {
  allFile (filter: { sourceInstanceName: { eq: "gallery" } }) {
    edges {
      node{
        name
        mtime
        absolutePath
        sourceInstanceName
        childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
          fixed(width: 640, height: 480) {
          ...GatsbyImageSharpFixed
        },
fluid {
          ...GatsbyImageSharpFluid
}

      }

      }
    }
  }
}
`
